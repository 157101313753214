import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { React } from 'react';
import { Route, Routes } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'simplebar';
import 'simplebar/dist/simplebar.css';
import ProtectedRoute from './ProtectedRoute';
import './assets/css/styles.min.css';
import './assets/js/app.min.js';
import './assets/js/sidebarmenu.js';
import About from './components/About/About';
import AddAbout from './components/About/AddAbout';
import AddBlogPost from './components/BlogPost/AddBlogPost';
import BlogPost from './components/BlogPost/BlogPost';
import BusinessProfile from './components/BusinessProfile';
import ColorCombination from './components/ColorCombination';
import AddCustomPages from './components/CustomPages/AddCustomPages';
import CustomPages from './components/CustomPages/CustomPages';
import Dashboard from './components/Dashboard';
import Enquiry from './components/Enquiry/Enquiry';
import AddFAQs from './components/FAQs/AddFAQs';
import FAQs from './components/FAQs/FAQs';
import Gallery from './components/Gallery/Gallery';
import Login from './components/Login';
import Logo from './components/Logo/Logo';
import AddNavigationMenu from './components/NavigationMenu/AddNavigationMenu';
import NavigationMenu from './components/NavigationMenu/NavigationMenu';
import PageNotFound from './components/PageNotFound';
import AddCategory from './components/ProductCategory/AddCategory';
import Category from './components/ProductCategory/Category';
import ProductReview from './components/ProductReview/ProductReview';
import AddSubCategory from './components/ProductSubCategory/AddSubCategory';
import SubCategory from './components/ProductSubCategory/SubCategory';
import AddProduct from './components/Products/AddProduct';
import Product from './components/Products/Product';
import ProductsList from './components/ProductsList';
import Slider from './components/Slider/Slider';
import Subscriber from './components/Subscriber/Subscriber';
import Taxes from './components/Taxes/Taxes';
import AddTeam from './components/Team/AddTeam';
import Team from './components/Team/Team';
import Testimonial from './components/Testimonial/Testimonial';
import AddTestimonial from './components/Testimonial/AddTestimonial';
import Videos from './components/Video/Video';
import ProductVariant from './components/Products/ProductVariant';
import AddCoupon from './components/Coupon/AddCoupon';
import Coupon from './components/Coupon/Coupon';
import AddDeal from './components/Deal/AddDeal';
import Deal from './components/Deal/Deal';
import Order from './components/Order/Order';
import AbandonedCart from './components/AbandonedCart/AbandonedCart';
import AbandonedWishlist from './components/AbandonedWishlist/AbandonedWishlist';
import Seo from './components/Seo';
import WebSetting from './components/WebSetting';
import User from './components/User/User'
import AddUser from './components/User/AddUser';
import Home from './components/Home.js';



function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ProtectedRoute element={<Dashboard />} isAdminRequired={true} />}>

          <Route path="/" element={<Home />} />
          
          <Route path="/businessProfile" element={<BusinessProfile />} />
          <Route path="/colorCombination" element={<ColorCombination />} />
          <Route path="/products" element={<ProductsList />} />

          <Route path="/about" element={<About />} />
          <Route path="/addAbout" element={<AddAbout />} />
          <Route path="/addAbout/:id" element={<AddAbout />} />
          <Route path="/addAbout/View/:id" element={<AddAbout />} />

          <Route path="/category" element={<Category />} />
          <Route path="/addCategory" element={<AddCategory />} />
          <Route path="/addCategory/:id" element={<AddCategory />} />
          <Route path="/addCategory/View/:id" element={<AddCategory />} />

          <Route path="/subCategory" element={<SubCategory />} />
          <Route path="/addSubCategory" element={<AddSubCategory />} />
          <Route path="/addSubCategory/:id" element={<AddSubCategory />} />
          <Route path="/addSubCategory/View/:id" element={<AddSubCategory />} />

          <Route path="/Taxes" element={<Taxes />} />

          <Route path="/navigationMenu" element={<NavigationMenu />} />
          <Route path="/addNavigationMenu" element={<AddNavigationMenu />} />
          <Route path="/addNavigationMenu/:id" element={<AddNavigationMenu />} />
          <Route path="/addNavigationMenu/View/:id" element={<AddNavigationMenu />} />

          <Route path="/team" element={<Team />} />
          <Route path="/addTeam" element={<AddTeam />} />
          <Route path="/addTeam/:id" element={<AddTeam />} />
          <Route path="/addTeam/View/:id" element={<AddTeam />} />

          <Route path="/logo" element={<Logo />} />

          <Route path="/Gallery" element={<Gallery />} />

          <Route path="/Slider" element={<Slider />} />

          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/addTestimonial" element={<AddTestimonial />} />
          <Route path="/addTestimonial/:id" element={<AddTestimonial />} />
          <Route path="/addTestimonial/View/:id" element={<AddTestimonial />} />

          <Route path="/enquiry" element={<Enquiry />} />

          <Route path="/productReview" element={<ProductReview />} />

          <Route path="/subscriber" element={<Subscriber />} />

          <Route path="/blogPost" element={<BlogPost />} />
          <Route path="/addBlogPost" element={<AddBlogPost />} />
          <Route path="/addBlogPost/:id" element={<AddBlogPost />} />
          <Route path="/addBlogPost/View/:id" element={<AddBlogPost />} />

          <Route path="/customPages" element={<CustomPages />} />
          <Route path="/addCustomPages" element={<AddCustomPages />} />
          <Route path="/addCustomPages/:id" element={<AddCustomPages />} />
          <Route path="/addCustomPages/View/:id" element={<AddCustomPages />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/addFAQs" element={<AddFAQs />} />
          <Route path="/addFAQs/:id" element={<AddFAQs />} />
          <Route path="/addFAQs/View/:id" element={<AddFAQs />} />

          <Route path="/product" element={<Product />} />
          <Route path="/addproduct" element={<AddProduct />} />
          <Route path="/addproduct/:id" element={<AddProduct />} />
          <Route path="/addproduct/View/:id" element={<AddProduct />} />

          <Route path="/variant/:id" element={<ProductVariant />} />

          <Route path="/coupon" element={<Coupon />} />
          <Route path="/addCoupon" element={<AddCoupon />} />
          <Route path="/addCoupon/:id" element={<AddCoupon />} />
          <Route path="/addCoupon/View/:id" element={<AddCoupon />} />

          <Route path="/deal" element={<Deal />} />
          <Route path="/addDeal" element={<AddDeal />} />
          <Route path="/addDeal/:id" element={<AddDeal />} />
          <Route path="/addDeal/View/:id" element={<AddDeal />} />

          <Route path="/order" element={<Order />} />

          <Route path="/abandonedCart" element={<AbandonedCart />} />

          <Route path="/abandonedWishlist" element={<AbandonedWishlist />} />
          
          <Route path="/seo" element={<Seo />} />
          
          <Route path="/webSetting" element={<WebSetting />} />

          <Route path="/user" element={<User />} />
          <Route path="/addUser" element={<AddUser />} />
          <Route path="/addUser/:id" element={<AddUser />} />
          <Route path="/addUser/View/:id" element={<AddUser />} />

          </Route>
          <Route path="/*" element={<PageNotFound />} />
          <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
