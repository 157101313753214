import axios from 'axios';
import { LOGIN_URL } from "../../apis/url_constants";
import { LOGOUT_URL } from '../../apis/url_constants';
import { toast } from 'react-toastify';

// Action Types
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const LOGIN_SUCCESS_NOTADMIN = 'LOGIN_SUCCESS_NOTADMIN';

// Action Creators
export const loginSuccess = (user) => {

  return {
    type: LOGIN_SUCCESS,
    payload: user
  };
};

export const loginSuccess_notAdmin = (user) => {
  
  return {
    type: LOGIN_SUCCESS_NOTADMIN,
    payload: user
  };
};

export const loginFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
    payload: error,
  };
};

export const logout = (navigate) => {
  return (dispatch) => {

    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');

    const headers = {
      Authorization: `Bearer ${token}`
    };

    axios.post(LOGOUT_URL, { token : refreshToken } , { headers })
      .then(() => {
        // Clear user-related data from local storage
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('isAdmin');
        
        // Dispatch the logout action
        dispatch({ type: LOGOUT });
        navigate('/login'); 
      })
      .catch((error) => {
        // Handle any error that occurs during logout
        console.error('Logout error:', error);
      });
  };
};


// Async Action Creator
export const login = (userData , navigate) => {

  return (dispatch) => {
    axios.post(LOGIN_URL, userData)
      .then((response) => {
        const user = response.data.user;
        const token = response.data.token;
        const refreshToken = response.data.refreshToken;
        const isAdmin = response.data.isAdmin;
        if(response.data.success){
          localStorage.setItem('token', token);
          localStorage.setItem('refreshToken', refreshToken);
          localStorage.setItem('isAdmin', isAdmin);

          if(response.data.isAdmin) 
          {
            dispatch(loginSuccess(user)) 
            navigate('/'); 
          }
          else{
            dispatch(loginSuccess_notAdmin(user))
            toast.error("Access Restricted")
          }
        }
        else{
          dispatch(loginFailure(response.data.errorMessage));
          toast.error(response.data.errorMessage)
        }
      })
      .catch((error) => {
        dispatch(loginFailure(error.message));
        toast.error("Something Went Wrong!")
      });
  };
};
